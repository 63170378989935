
/*=========================================================================*/

/* [Validation Styles] */

.validation-overlay
{
  background-color: #000;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  background-color: rgba(0,0,0,.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1000;
}

.validation-error
{
  position: relative;
  z-index: 10001;
  -webkit-box-shadow: 0 0 16px 0 #222222;
  box-shadow: 0 0 16px 0 #222222;
}

.form-group.validation-error
{
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  z-index: 1001;
}

/*=========================================================================*/

/* [Wizard Styles] */

.wizard
{
  padding: 0;
  padding-right: 21px;
  margin: 0 0 10px;

  list-style-type: none;

  font-family: arial;
  font-size: 12px;
  line-height: 1em;
  text-align: center;
}

.wizard::before,
.wizard::after
{
  content: " ";
  display: table;
  clear: both;
}

.wizard li
{
  float: left;
  padding: 0;
  position: relative;
  width: calc(33.33% - 4px);
  margin: 0 2px;
  box-sizing: border-box;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;

  background: #eeeeee;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;

  color: #ffffff;
}

.wizard-3 li
{
  width: calc(33.33% - 4px);
}

.wizard-4 li
{
  width: calc(25% - 4px);
}

.wizard li::before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;

  border-left: 21px solid #ffffff;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
}

.wizard li::after
{
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;

  border-left: 21px solid #eeeeee;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
}

.wizard li:hover
{
  background-color: #A5D03E;
}

.wizard li:hover::after
{
  border-left: 21px solid #A5D03E;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
}

.wizard li a
{
  display: block;
  padding: 10px 30px 10px 40px;
  height: 42px;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
  line-height: 1.5em;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
}

.wizard li a:hover
{
  text-decoration: none;
}

.wizard li button
{
  display: block;
  padding: 10px 30px 10px 40px;
  height: 42px;
  color: #333333;
  font-size: 14px;
  line-height: 1.5em;
  box-sizing: border-box;
  margin: 0;
  width: 100%;

  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;

  text-shadow: none;
}

.wizard li.active
{
  background-color: #81C14D;
}

.wizard li.active::after
{
  border-left-color: #81C14D;
}

.wizard li.active a,
.wizard li.active button
{
  color: #ffffff;
}

.wizard input[type="button"]:disabled,
.wizard button:disabled
{
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

/*=========================================================================*/

/* [Popover Shopping Cart Styles] */

.shopping-cart-popover {
  position: absolute;
  right: 0;
  top: 0;
  background: #ddd;
  border-radius: 0 0 4px 4px;
  z-index: 1000;
}

#popover-cart {
  display: none;
}

.shopping-cart-popover .shopping-cart-popover-container {
  min-width: 0px;
  max-width: 70px;
  max-height: 40px;
  overflow: hidden;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.shopping-cart-popover .btn-cart {
  background: none;
}

.shopping-cart-popover .btn-cart:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.shopping-cart-popover .btn-open-cart {
  display: inline-block;
}

.shopping-cart-popover .btn-close-cart {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.shopping-cart-popover .btn-cart-label {
  display: none;
  pointer-events: none;
  cursor: default;
}

.shopping-cart-popover .shopping-cart-popover-body,
.shopping-cart-popover .shopping-cart-popover-footer {
  padding: 0;
  height: 0;
  width: 0;
  opacity: 0;
  transform: translateX(20px);
}

.shopping-cart-popover .shopping-cart-popover-body .table {
  margin-bottom: 0;
}

#popover-cart:checked ~ .shopping-cart-popover-container {
  min-width: 300px;
  max-width: 300px;
  max-height: 400px;
}

#popover-cart:checked ~ .shopping-cart-popover-container .btn-open-cart {
  display: none;
}

#popover-cart:checked ~ .shopping-cart-popover-container .btn-cart-label {
  display: inline-block;
}

#popover-cart:checked ~ .shopping-cart-popover-container .btn-close-cart {
  opacity: 1;
  z-index: auto;
  -webkit-transition: opacity 300ms ease-out;
  -moz-transition: opacity 300ms ease-out;
  -o-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out;
}

#popover-cart:checked ~ .shopping-cart-popover-container .shopping-cart-popover-body,
#popover-cart:checked ~ .shopping-cart-popover-container .shopping-cart-popover-footer {
  border-top: 1px solid #333;
  padding: 12px;
  height: auto;
  width: auto;
  opacity: 1;
  -webkit-transition: opacity 300ms 300ms ease-out, transform 300ms 300ms ease-out;
  -moz-transition: opacity 300ms 300ms ease-out, transform 300ms 300ms ease-out;
  -o-transition: opacity 300ms 300ms ease-out, transform 300ms 300ms ease-out;
  transition: opacity 300ms 300ms ease-out, transform 300ms 300ms ease-out;
  transform: translateX(0px);
}

#popover-cart:checked ~ .shopping-cart-popover-container .shopping-cart-popover-footer {
  -webkit-transition: opacity 300ms 500ms ease-out, transform 300ms 500ms ease-out;
  -moz-transition: opacity 300ms 500ms ease-out, transform 300ms 500ms ease-out;
  -o-transition: opacity 300ms 500ms ease-out, transform 300ms 500ms ease-out;
  transition: opacity 300ms 500ms ease-out, transform 300ms 500ms ease-out;
  transform: translateX(0px);
}

/*=========================================================================*/

/* [Cart Table Styles] */

.table-cart th,
.table-cart td
{
  vertical-align: middle;
}

.table-cart td.text-right
{
  text-align: right !important;
}

.table-cart td.text-center
{
  text-align: center !important;
}

.table-cart input[type="text"]
{
  margin: 0;

  box-shadow: none;
}

.table-cart .tc-footer td {
  border-top: 1px solid transparent;
  background: #ffffff;
}

.table-cart .tc-footer td.tc-footer-bordered {
  border-top: 1px solid #ddd;
  background: transparent;
  padding-left: 12px;
}

.table-cart .tc-footer td:last-of-type {
  padding: 0;
}

.bg-white {
  background: #ffffff;
}

@media screen and (max-width: 640px) {
  .table-cart
  {
    overflow-x: auto;
    display: block;
  }
}

/*=========================================================================*/

/* [Cart Form Styles] */

.form-checkout .control-group
{
  margin: 0;
}

.form-checkout select,
.form-checkout textarea,
.form-checkout input[type="text"],
.form-checkout input[type="password"],
.form-checkout input[type="datetime"],
.form-checkout input[type="datetime-local"],
.form-checkout input[type="date"],
.form-checkout input[type="month"],
.form-checkout input[type="time"],
.form-checkout input[type="week"],
.form-checkout input[type="number"],
.form-checkout input[type="email"],
.form-checkout input[type="url"],
.form-checkout input[type="search"],
.form-checkout input[type="tel"],
.form-checkout input[type="color"],
.form-checkout .uneditable-input
{
  padding-top: 6px;
  padding-bottom: 6px;
  height: auto;

  box-shadow: none;
}

/*=========================================================================*/

/* [Form Input Styles] */

.form-styled .form-control
{
  box-sizing: border-box;
  border-radius: 0;
  border-width: 2px;
  border-color: #ffffff;
  background: #dddddd;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 10px 16px;
  min-height: 44px;
  width: 100%;
  display: block;
}

.form-styled .help-block
{
  margin: 0 6px;
}

.form-styled.form-group.has-error .form-control
{
  background: #f2dede;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #a94442;
}
.form-styled.form-group.focus .form-control
{
  border-color: #c09853;
  background: #ffffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-styled.form-group.filled .form-control
{
  border-color: #337ab7;
}
.form-styled.form-group.has-error.filled .form-control
{
  border-color: #a94442;
}
.form-styled .control-label
{
  position: relative;
  display: block;

  top: 17px;
  padding-left: 10px;
  cursor: text;
  font-weight: bold;
  color: #888888;
  height: 0;
  -webkit-transition: height linear 0.2s, top linear 0.2s, padding-left linear 0.2s 0.1s, color linear 0.2s;
  -moz-transition: height linear 0.2s, top linear 0.2s, padding-left linear 0.2s 0.1s, color linear 0.2s;
  -o-transition: height linear 0.2s, top linear 0.2s, padding-left linear 0.2s 0.1s, color linear 0.2s;
  transition: height linear 0.2s, top linear 0.2s, padding-left linear 0.2s 0.1s, color linear 0.2s;
}
.form-styled.form-group.focus .control-label
{
  top: 0px;
  padding-left: 0px;
  cursor: pointer;
  height: auto;
  color: #c09853;
}
.form-styled.form-group.filled .control-label
{
  color: #337ab7;
}
.form-styled.form-group.has-error.filled .control-label
{
  color: #a94442;
}


/* [Checkbox & Radio Input Styling] */

.checkbox-styled label,
.radio-styled label
{
  cursor: default;
}

.checkbox-styled input[type="checkbox"],
.radio-styled input[type="radio"]
{
  display: none;
}

.checkbox-styled input[type="checkbox"] + span::before,
.radio-styled input[type="radio"] + span::before
{
  border: 1px solid #aaa;
  border-radius: 3px;
  font-family: 'Glyphicons Halflings';
  content: "";
  font-size: 9px;
  padding-top: 2px;
  margin-right: 6px;
  width: 18px;
  height: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: relative;
  top: -1px;
  cursor: pointer;
}

.checkbox-styled input[type="checkbox"] + span,
.radio-styled input[type="radio"] + span
{
  cursor: pointer;
}

.checkbox-styled input[type="checkbox"]:checked + span::before,
.radio-styled input[type="radio"]:checked + span::before
{
  content: "\e013";
  color: #337ab7;
  border-color: #337ab7;
  border-width: 2px;
  padding-top: 1px;
}

.checkbox-styled input[type="checkbox"]:checked + span,
.radio-styled input[type="radio"]:checked + span
{
  color: #337ab7;
}

.checkbox-styled input[type="checkbox"]:disabled + span::before,
.radio-styled input[type="radio"]:disabled + span::before
{
  color: #888888;
  border-color: #888888;
  cursor: not-allowed;
}

.checkbox-styled input[type="checkbox"]:disabled + span,
.radio-styled input[type="radio"]:disabled + span
{
  color: #888888;
  cursor: not-allowed;
}

.radio-styled input[type="radio"] + span::before
{
  border-radius: 50%;
}




.m-b-n {
  margin-bottom: 0;
}