/* Generated by Glyphter (http://www.glyphter.com) on  Tue Sep 06 2016*/
@font-face {
    font-family: 'social icons';
    src: url('/fonts/social-icons.eot');
    src: url('/fonts/social-icons.eot?#iefix') format('embedded-opentype'),
    url('/fonts/social-icons.woff') format('woff'),
    url('/fonts/social-icons.ttf') format('truetype'),
    url('/fonts/social-icons.svg#social-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.si {
    line-height: 1;
    vertical-align: middle;
}

.si::before {
    display: inline-block;
    font-family: 'social icons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.si-facebook::before    { content: '\0041'; }
.si-google::before      { content: '\0042'; }
.si-google-plus::before { content: '\0042'; }
.si-linkedin::before    { content: '\0043'; }
.si-pinterest::before   { content: '\0044'; }
.si-twitter::before     { content: '\0045'; }
.si-youtube::before     { content: '\0046'; }

.si-facebook.si-circle::before      { content:'\0047'; }
.si-google.si-circle::before        { content:'\0048'; }
.si-google-plus.si-circle::before   { content:'\0048'; }
.si-linkedin.si-circle::before      { content:'\0049'; }
.si-pinterest.si-circle::before     { content:'\004a'; }
.si-twitter.si-circle::before       { content:'\004b'; }
.si-youtube.si-circle::before       { content:'\004c'; }

.si-facebook.si-rounded::before     { content:'\004d'; }
.si-google.si-rounded::before       { content:'\004e'; }
.si-google-plus.si-rounded::before  { content:'\004e'; }
.si-linkedin.si-rounded::before     { content:'\004f'; }
.si-pinterest.si-rounded::before    { content:'\0050'; }
.si-twitter.si-rounded::before      { content:'\0051'; }
.si-youtube.si-rounded::before      { content:'\0052'; }

.si-circle::before {
    border-radius: 100%;
}

.si-rounded::before {
    border-radius: 15%;
}